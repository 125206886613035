<template>
    <div>
        <div id="container" style="width: 100%; height: 400px;"></div>
    </div>
</template>

<script>
// Import the full Highcharts Stock package
import Highcharts from 'highcharts/highstock';

export default {
    props: {
        prices: {
            type: Object,
            required: true,
        },
    },

    computed: {
        // Convert the prices object to the format Highcharts expects
        mappedPrices() {
            return Object.entries(this.prices).map(([timestamp, price]) => {
                // Convert timestamp to number if it's a string
                const time = typeof timestamp === 'string' ? new Date(timestamp).getTime() : Number(timestamp);
                // Keep the division by 100 since prices are in cents
                return [time, price / 100];
            }).sort((a, b) => a[0] - b[0]); // Sort by timestamp
        },

        // Calculate min and max price values for y-axis
        priceRange() {
            if (!this.mappedPrices.length) return { min: 2, max: 3 }; // Default range for beer prices

            const prices = this.mappedPrices.map(point => point[1]);
            const min = Math.min(...prices);
            const max = Math.max(...prices);

            // Add padding (10% on both sides)
            const padding = (max - min) * 0.1;
            return {
                min: Math.max(0, min - padding), // Don't go below 0
                max: max + padding
            };
        }
    },

    mounted() {
        // Create the chart when component is mounted
        this.createChart();
    },

    methods: {
        createChart() {
            // Use mappedPrices or fallback to sample beer price data if no data
            const seriesData = this.mappedPrices.length > 0 ? this.mappedPrices : [
                [1609459200000, 2.50],
                [1609545600000, 2.65],
                [1609632000000, 2.45],
                [1609718400000, 2.70],
                [1609804800000, 2.40],
                [1609891200000, 2.55],
                [1609977600000, 2.60],
                [1610064000000, 2.50],
            ];

            // Calculate the average price for threshold
            const pricesOnly = seriesData.map(point => point[1]);
            const avgPrice = pricesOnly.reduce((sum, price) => sum + price, 0) / pricesOnly.length;

            // Average price line data - use calculated average for each timestamp
            const avgData = seriesData.map(point => [point[0], avgPrice]);

            // Enable Highcharts boost module for better performance with large datasets
            // We need to set this up before creating the chart
            if (Highcharts.seriesTypes.area) {
                // Only set boost settings if area series type exists
                Highcharts.seriesTypes.area.prototype.noSharedTooltip = false;
            }

            const chartOptions = {
                chart: {
                    backgroundColor: '#FFFFFF',
                    plotBorderWidth: 0,
                    spacing: [10, 10, 15, 10],
                    zoomType: 'x',
                    events: {
                        // Handle range selector button clicks to adjust data grouping
                        load: function() {
                            const chart = this;

                            // Set up button click handlers for range selector
                            Highcharts.addEvent(chart.rangeSelector, 'afterBtnClick', function() {
                                const rangeDays = getRangeDays(chart);
                                updateChartAppearance(chart, rangeDays);
                            });

                            // Set up input change handlers for range selector
                            if (chart.rangeSelector.inputGroup) {
                                chart.rangeSelector.inputGroup.on('click', function() {
                                    setTimeout(function() {
                                        const rangeDays = getRangeDays(chart);
                                        updateChartAppearance(chart, rangeDays);
                                    }, 100);
                                });
                            }

                            // Initial appearance setup
                            const initialRangeDays = getRangeDays(chart);
                            updateChartAppearance(chart, initialRangeDays);
                        }
                    }
                },
                title: {
                    text: null,
                },
                xAxis: {
                    lineWidth: 0,
                    tickWidth: 0,
                    labels: {
                        style: { fontSize: '12px', color: '#808A9D' },
                    },
                    gridLineWidth: 0,
                    events: {
                        // Update appearance when user zooms
                        afterSetExtremes: function(e) {
                            const chart = this.chart;
                            const rangeDays = (e.max - e.min) / (24 * 3600 * 1000);
                            updateChartAppearance(chart, rangeDays);
                        }
                    }
                },
                yAxis: {
                    opposite: true,
                    labels: {
                        style: { fontSize: '12px', color: '#808A9D' },
                        formatter: function() {
                            return '€ ' + this.value.toFixed(2);
                        }
                    },
                    min: this.priceRange.min,
                    max: this.priceRange.max,
                    gridLineColor: 'rgba(128, 138, 157, 0.1)',
                },
                tooltip: {
                    backgroundColor: '#FFFFFF',
                    borderColor: '#EAECEF',
                    borderRadius: 8,
                    shadow: true,
                    style: { color: '#333' },
                    shared: true,
                    useHTML: true,
                    formatter: function() {
                        const date = new Date(this.x).toLocaleDateString();
                        return `<b>${date}</b><br>Prijs: €${this.y.toFixed(2)}`;
                    },
                    valueDecimals: 2
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        },
                        // Keep dataGrouping but we'll adjust it based on view
                        dataGrouping: {
                            enabled: true,
                            approximation: 'average',
                            // Units in milliseconds - important for daily data
                            units: [
                                ['day', [1]],
                                ['week', [1]],
                                ['month', [1]],
                            ],
                            dateTimeLabelFormats: {
                                day: ['%b %e', '%b %e', '-%b %e'],
                                week: ['%b %e', '%b %e', '-%b %e'],
                                month: ['%b \'%y', '%b \'%y', '-%b \'%y'],
                            },
                            // smoothed is set to true by default
                            smoothed: true
                        },
                        connectNulls: true,
                        gapSize: 1,
                        gapUnit: 'relative',
                    },
                    area: {
                        lineWidth: 2,
                        states: {
                            hover: {
                                lineWidth: 2
                            }
                        },
                        animation: {
                            duration: 300
                        }
                    },
                    areaspline: {
                        lineWidth: 2,
                        states: {
                            hover: {
                                lineWidth: 2
                            }
                        },
                        // Used for the spline options
                        marker: {
                            enabled: false,
                            radius: 3,
                            lineWidth: 1
                        }
                    }
                },
                series: [
                    {
                        // Series type will be dynamically updated based on view
                        type: 'area',
                        name: 'Bier prijs',
                        data: seriesData,
                        color: 'rgb(22, 199, 132)', // Green for the line
                        negativeColor: '#EA3943', // Red for line below threshold
                        threshold: avgPrice, // Use calculated average
                        marker: {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: true,
                                    radius: 4
                                }
                            }
                        },
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1,
                            },
                            stops: [
                                [0, 'rgba(22, 199, 132, 0.3)'], // Green gradient
                                [1, 'rgba(255, 255, 255, 0.1)'], // Fade to white
                            ],
                        },
                        negativeFillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1,
                            },
                            stops: [
                                [0, 'rgba(234, 57, 67, 0.3)'], // Red gradient
                                [1, 'rgba(255, 255, 255, 0.1)'], // Fade to white
                            ],
                        },
                    },
                    {
                        // Average line type will be dynamically updated based on view
                        type: 'line',
                        name: 'Average Price',
                        data: avgData,
                        color: '#999999',
                        lineWidth: 1,
                        dashStyle: 'dot',
                        zIndex: 5,
                        enableMouseTracking: false,
                        states: {
                            hover: {
                                enabled: false,
                            },
                        },
                    },
                ],
                navigator: {
                    enabled: true,
                    series: {
                        type: 'line', // Will be dynamically updated
                        lineWidth: 1,
                        color: 'rgba(22, 199, 132, 0.6)',
                        fillOpacity: 0.05,
                        dataGrouping: {
                            approximation: 'average',
                            smoothed: true,
                            groupPixelWidth: 10
                        }
                    },
                    xAxis: {
                        labels: {
                            style: { fontSize: '10px' }
                        }
                    }
                },
                rangeSelector: {
                    buttons: [
                        { type: 'day', count: 7, text: '7d' },
                        { type: 'month', count: 1, text: '1m' },
                        { type: 'month', count: 3, text: '3m' },
                        { type: 'year', count: 1, text: '1y' },
                        { type: 'all', text: 'All' },
                    ],
                    selected: 1,
                    inputEnabled: false,
                },
                crosshair: {
                    color: '#999999',
                    dashStyle: 'dot',
                    width: 1,
                    zIndex: 5,
                },
                credits: {
                    enabled: false
                },
                time: {
                    useUTC: false
                }
            };

            // Function to get number of days in current chart range
            function getRangeDays(chart) {
                const extremes = chart.xAxis[0].getExtremes();
                const rangeDays = (extremes.max - extremes.min) / (24 * 3600 * 1000);
                return rangeDays;
            }

            // Function to update chart appearance based on range
            function updateChartAppearance(chart, rangeDays) {
                const mainSeries = chart.series[0];
                const avgSeries = chart.series[1];
                const navigatorSeries = chart.navigator && chart.navigator.series[0];

                if (!mainSeries) return;

                let groupingUnit, groupingMultiple;
                let useSplineType = true; // Default to standard area

                // Adjust grouping and chart type based on date range
                // For daily data points, we need to be careful with grouping
                if (rangeDays <= 30) {
                    // For shorter periods - show actual daily data
                    groupingUnit = 'day';
                    groupingMultiple = 1;
                } else if (rangeDays <= 90) {
                    // For 3m - group by 3 days and use spline for smoother appearance
                    groupingUnit = 'day';
                    groupingMultiple = 1;
                } else if (rangeDays <= 365) {
                    // For 1y - group by week
                    groupingUnit = 'day';
                    groupingMultiple = 1;
                } else {
                    // For all - group by 3 days.
                    groupingUnit = 'day';
                    groupingMultiple = 3;
                }

                // Update main series type and grouping
                const updatedType = useSplineType ? 'areaspline' : 'area';
                const avgUpdatedType = useSplineType ? 'spline' : 'line';
                const navUpdatedType = useSplineType ? 'areaspline' : 'area';

                // Only update if needed
                if (mainSeries.type !== updatedType ||
                    !mainSeries.options.dataGrouping ||
                    mainSeries.options.dataGrouping.units[0][0] !== groupingUnit ||
                    mainSeries.options.dataGrouping.units[0][1] !== groupingMultiple) {

                    // Update main series
                    mainSeries.update({
                        type: updatedType,
                        dataGrouping: {
                            enabled: true,
                            forced: true,
                            units: [[groupingUnit, [groupingMultiple]]],
                            smoothed: true
                        }
                    }, false);

                    // Update average line
                    if (avgSeries) {
                        avgSeries.update({
                            type: avgUpdatedType,
                            dataGrouping: {
                                enabled: true,
                                forced: true,
                                units: [[groupingUnit, [groupingMultiple]]],
                                smoothed: true
                            }
                        }, false);
                    }

                    // Update navigator if it exists
                    if (navigatorSeries) {
                        navigatorSeries.update({
                            type: navUpdatedType,
                            dataGrouping: {
                                approximation: 'average',
                                smoothed: true,
                                units: [[groupingUnit, [groupingMultiple]]]
                            }
                        }, false);
                    }

                    chart.redraw();
                }
            }

            // Create the stock chart
            try {
                Highcharts.stockChart('container', chartOptions);
            } catch (error) {
                console.error('Error creating chart:', error);

                // Fallback to basic chart if stock chart fails
                try {
                    // Create a simplified version without Stock features
                    const basicOptions = {
                        ...chartOptions,
                        chart: {
                            ...chartOptions.chart,
                            type: 'area'
                        },
                        time: {
                            useUTC: false
                        }
                    };

                    Highcharts.chart('container', basicOptions);
                } catch (fallbackError) {
                    console.error('Fallback chart also failed:', fallbackError);
                }
            }
        }
    },

    // Update chart when props change
    watch: {
        prices: {
            handler() {
                this.createChart();
            },
            deep: true
        }
    }
}
</script>
